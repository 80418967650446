import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import CardSection from "../components/CardSections";

const Home = () => (
  <Fragment>
    <Hero />
    {/* <CardSection /> */}
    {/* <hr /> */}
    {/* <Content /> */}
  </Fragment>
);

export default Home;
