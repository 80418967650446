import React from 'react';
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

function CardSection() {
  const { loginWithRedirect } = useAuth0();

  const cards = [
    { 
      image: 'https://media.restorationhardware.com/is/image/rhis/07072023_homepage_architecture_new?$responsive-gallery-image$&wid=2000&bfc=on', 
      title: 'Default Login', 
      text: 'Calling LoginWithRedirect.', 
      buttonText: 'Login',
      authParams: { organization: 'org_qL9vC3tIKO7dlFAP' }
    },
      // ... Add other card details similarly
      { 
      image: 'https://www.carrieunderwoodofficial.com/files/2022/03/Ghost-Story-Single-Cover-Art-high-res.jpeg', 
      title: 'Carrie Underwood', 
      text: 'Example of changing the branding based on Org.', 
      buttonText: 'Login',
      authParams: { organization: 'org_dH20wW7Nbq9ER7uW' }
      },
      { 
      image: 'https://cache.umusic.com/_sites/_halo/zrskt/nwff/th/td-po.jpg', 
      title: 'Taylor Swift', 
      text: 'Demonstrates using a header, banner and footer.', 
      buttonText: 'Login',
      authParams: { organization: 'org_VJtkhtNFc5CxcrPw' }
    },
  ];

  return (
    <div className="container mt-5">
      <div className="row">
        {cards.map((card, index) => (
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index}>
            <div className="card">
              <img src={card.image} className="card-img-top card-img-top card-image-custom" alt={card.title} />
              <div className="card-body">
                <h5 className="card-title">{card.title}</h5>
                <p className="card-text">{card.text}</p>
                <Button 
                  color="primary" 
                  onClick={() => loginWithRedirect({ authorizationParams: card.authParams })}
                >
                  {card.buttonText}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardSection;


