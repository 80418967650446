import React from "react";

import umglogo from "../assets/umglogo.png";
import LoginButton from '../components/LoginButton';
import LoginModal from '../components/LoginModal';
import { Link } from 'react-router-dom';

import {
  
  Button,

} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const Hero = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
// const toggle = () => setIsOpen(!isOpen);
const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

  return (
  <div className="text-center hero my-5">
      {/* <img className="mb-3 app-logo" src={umglogo} alt="React logo" width="200" /> */}
      <h1 className="mb-4">Sample Branding Per Organizations Using NUL</h1>

      <p className="lead">
        This is a sample application that demonstrates custom branding capabilities.  
      </p>

       <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
    >
      Custom Sign Up 
    </Button>
       <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_qL9vC3tIKO7dlFAP' } })}
    >
      Login with OrgA
    </Button>
     <Button
      id="qsLoginBtn"
      color="primary"
        className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_dXlOpqZ29pDKLX7J' } })}
    >
      Login with OrgB
      </Button>
      {/* <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_VJtkhtNFc5CxcrPw' } })}
    >
      Taylor Swift
      </Button> */}
      <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_6dOgmIy9dIDkBMBo' } })}
    >
      Video Background
    </Button>
      

      <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_qL9vC3tIKO7dlFAP', connection: 'sms' } })}
    >
      Login with SMS
      </Button>
      
      <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect()}
    >
      Login Flow 
    </Button>
     
      <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_qL9vC3tIKO7dlFAP', connection: 'ent-connection', sessionID: 'BRIZET-SERFID-ESADF' } })}
    >
      Login with Ent. OIDC
      </Button>
      
      <Button
      id="qsLoginBtn"
      color="primary"
      className="btn-margin btn-pad"
        onClick={() => loginWithRedirect({ authorizationParams: { organization: 'org_cUjyVShFMhXULPXg' } })}
    >
      Carousel
      </Button>
      {/* <LoginModal /> */}
      <br></br>
      <div>
      
      {/* <Link to="/smsLogin">Login with SMS</Link> */}
    </div>
    </div>  
  );
   

  
};



export default Hero;
